import requests from '@/api/request.js'
import Vue from 'vue'
// 节流: n 秒内只运行一次，若在 n 秒内重复触发，只有一次生效
// 防抖: n 秒后在执行该事件，若在 n 秒内被重复触发，则重新计时

/**
 * 节流
 * time:节流的时间
 * callback:回调方法
 * params:回调方法的参数，是个数组
 */
class LimitFlow {
    constructor(duration) {
        this.duration = duration
        this.flag = true
    }
    do(startCallback, enddingCallback, params) {
        if (this.flag) {
            this.flag = false
            startCallback && startCallback(params?.startParams)
            setTimeout(() => {
                enddingCallback && enddingCallback(params?.enddingParams)
                this.flag = true
            }, this.duration);
        }
    }
}

/**
 * 防抖
 * callback2:计时器清除之前的操作
 */

class NotShake {
    id = 0
    do(time, callback, params = null, callback2) {
        clearTimeout(this.id)
        callback2 && callback2()
        this.id = setTimeout(() => {
            params ? callback(...params) : callback()
        }, time)
    }
}


function notshake() {
    return new NotShake()
}
function limitflow() {
    return new LimitFlow()
}


const delTemplate = async function (title = '此操作将永久删除该项, 是否继续?') {
    return await this.$confirm(title, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
    }).then(() => true).catch(() => {
        this.$message({
            type: 'info',
            message: '已取消删除'
        })
        return false
    })
}

/**
 * @function 表格的新增/修改/删除
 * @param table_key {String} 表格数组的属性名
 */
const changeTable = function (table_key) {
    const temp = { addArr: new Set(), delArr: new Set(), editArr: new Set() }
    const execute = () => {
        console.log(temp)
        return temp
    }
    execute.clear = () => {
        temp.addArr.clear()
        temp.delArr.clear()
        temp.editArr.clear()
    }
    execute.add = function (...args) {
        // 有自定义方法
        const fn = args.find(item => typeof item === 'function')
        if (fn && fn()) {
            return 0
        }
        const [row] = args

        // 改变原表格
        const table_index = this[table_key].findIndex(item => item.id === '-')
        if (table_index == -1) {
            this.$set(this[table_key], this[table_key].length, row)
        } else {
            this.$set(this[table_key], table_index, row)
        }
        // 添加到缓存
        temp.addArr.add(row)
    }
    execute.del = function (...args) {
        // 有自定义方法
        const fn = args.find(item => typeof item === 'function')
        if (fn && fn()) {
            return 0
        }
        const [row] = args
        // const idtype = row.id ? 'id' : 'hId'
        // 改变原表格
        const index = this[table_key].findIndex(item => item === row)
        this[table_key].splice(index, 1)

        if (temp.addArr.has(row)) {
            temp.addArr.delete(row)
            return
        }
        if (temp.editArr.has(row)) {
            temp.editArr.delete(row)
        }
        temp.delArr.add(row)
    }
    execute.edit = function (...args) {
        const [row] = args
        // const idtype = row.id ? 'id' : 'hId'
        // 有自定义方法
        const fn = args.find(item => typeof item === 'function')
        if (fn && fn()) {
            return 0
        }
        // 改变原表格
        const table_index = this[table_key].findIndex(item => item === row)
        this.$set(this[table_key], table_index, row)

        if (temp.addArr.has(row)) {
            temp.addArr.add(row)
            return
        }
        // 如果修改存在则替换   hId和id都是唯一的
        if (temp.editArr.has(row)) {
            temp.editArr.add(row)
            return
        }
        temp.editArr.add(row)
    }
    return execute
}

/**
 * 下载文件(导出)
 */
class DownLoadFiles {
    constructor(params) {
        this.url = params.url
        this.data = params.data
    }
    async download() {
        const result = await requests({
            method: 'post',
            url: this.url,
            data: this.data,
            header: {
                headers: { 'Content-Type': 'application/x-download' }
            },
            responseType: 'blob'
        })
        if (!result.name) {
            const reader = new FileReader()
            reader.readAsText(result)
            reader.onload = () => {
                const objres = JSON.parse(reader.result)
                Vue.prototype.$message({
                    message: objres.msg,
                    type: 'error'
                })
            }
            return
        }

        const xlsx = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        const blob = new Blob([result.data], { type: xlsx })
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.download = decodeURIComponent(result.name)
        a.href = url
        a.click()
        a.remove()
    }

}
// 递归遍历字符串urlencode编码
const entriesEncode = (param) => {
    // 是数组
    if (Array.isArray(param)) {
        for (let i = 0; i < param.length; i++) {
            if (param[i] instanceof String) {
                param[i] = entriesEncode(param[i]);
            }
        }
    }
    // 是对象
    if (param instanceof Object) {
        console.log('is object');
        for (let key in param) {
            if (typeof (param[key]) == 'string') {
                param[key] = entriesEncode(param[key]);
                console.log(param[key]);
            }
        }
    }
    if (typeof (param) == 'string') {
        console.log('is string');
        return encodeURIComponent(param)
    }
    return param
}

// 去除首尾的特殊字符
const delSmt = (param) => {
    // 是数组
    if (Array.isArray(param)) {
        for (let i = 0; i < param.length; i++) {
            if (param[i] instanceof String) {
                param[i] = delSmt(param[i]);
            }
        }
    }
    // 是对象
    if (param instanceof Object) {
        console.log('is object');
        for (let key in param) {
            if (typeof (param[key]) == 'string') {
                param[key] = delSmt(param[key]);
            }
        }
    }
    if (typeof (param) == 'string') {
        param = param.replace(/^(\s|\+)+|(\s|\+)+$/g, '')    //把首尾的+全部去掉
        return param
    }
    return param
}
// 编码完善
const fullurlEncode = (str) => {
    str = encodeURIComponent(str)   //编码
    str = str.replace(/\!/g, '%21')
    str = str.replace(/\*/g, '%2A')
    str = str.replace(/\'/g, '%27')
    str = str.replace(/\(/g, '%28')
    str = str.replace(/\)/g, '%29')
    str = str.replace(/\%2B/, '%20')
    return str
}
// 检测是否为移动设备(touch事件)
const isMobile = () => {
    return ('ontouchstart' in document.documentElement)
}
export { notshake, limitflow, delTemplate, changeTable, DownLoadFiles, isMobile, entriesEncode, fullurlEncode,delSmt }